import ApiRoutes from '@/store/transport/ApiRoutes'
import HTTPService from '@/store/transport/HTTPService'
import axios from 'axios'

export function printTasks({ taskIds, onSuccess, onFailed }) {
  axios.request({
    url: ApiRoutes.printTasksURL(),
    method: 'POST',
    responseType: 'blob',
    headers: getHeaders(),
    params: {
      task_ids: taskIds
    }
  })
    .then(response => response.data)
    .then(blob => {
      const data = URL.createObjectURL(blob)
      var link = document.createElement('a')
      link.href = data
      link.target = '_blank'
      link.click()
      window.URL.revokeObjectURL(blob)

      if (onSuccess) {
        onSuccess()
      }
    })
    .catch((error) => {
      if (onFailed) {
        onFailed(error)
      }
    })
}

function getHeaders() {
  const headers = HTTPService._getRequestHeaders()
  /* eslint-disable dot-notation */
  delete headers['Accept']
  delete headers['Content-Type']
  /* eslint-enable dot-notation */
  headers['Access-Control-Allow-Origin'] = '*'
  return headers
}
