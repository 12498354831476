<template>
  <div class="print-page">
    <navbar/>
    <div class="container">
      <vue-position-sticky :offsetTop="12">
        <div class="columns print-action-bar">
          <div class="column">
            <div class="columns is-variable is-1">
              <div class="column">
                <b-button
                  class="print-button"
                  type="is-primary"
                  expanded
                  :disabled="checkedTasks.length === 0"
                  @click="print">
                  {{ $t('print.printButtonTitle') }}
                </b-button>
              </div>
              <div class="column is-three-quarters">
                <search-field
                  :delay="500"
                  :placeholder="$t('print.searchPlaceholder')"
                  expanded
                  @search="onSearchInput"/>
              </div>
            </div>
          </div>
          <div class="column">
            <b-button
              class="print-button"
              type="is-secondary"
              @click="showCourierNameOverrideForm">
              Courier Name Override
            </b-button>
          </div>
        </div>
      </vue-position-sticky>
      <div class="columns print-selection-section">
        <div
          v-for="column in columns"
          :key="`print_page_${column.type}_column`"
          class="column">
          <a
            class="is-pulled-right"
            @click="selectAll(column.type)">
            {{ $t('print.selectAllLabel') }}
          </a>
          <h6 class="title is-size-6">
            {{ $t(`print.${column.type}Label`) }}
          </h6>
          <div class="print-trips">
            <template v-if="tasksLoading">
              {{ $t('common.loading') }}
            </template>
            <template v-else-if="column.trips.length === 0">
              {{ $t(`print.empty.${column.type}`) }}
            </template>
            <print-trip
              v-for="trip in column.trips"
              :key="`print_trip_${trip.tripId}_${column.type}`"
              :ref="`trip_${trip.tripId}_${column.type}`"
              :trip="trip"
              :type="column.type"
              :isOpen="!!searchTerm || column.type === PRINT_TYPES.UNPRINTED"
              :checkedTasks.sync="checkedTasks"/>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      :active.sync="courierNameOverrideFormModalActive"
      :width="320"
      animation
      class="courier-name-override-form-modal">
      <courier-name-override-form
        @confirm="confirmCourierNameOverrideForm"/>
    </b-modal>
    <b-loading :active="loadingOverlayVisible"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import apiWithFiltersMixin from '@/mixins/api-with-filters-mixin'
import { formatDate } from '@js/utils'
import fulfilmentChannelMixin from '@/mixins/fulfilment-channel-mixin'
import { printTasks } from '@js/print-receipt-utils'

const Navbar = () => import('@components/Navbar')
const PrintTrip = () => import('@components/PrintTrip')
const SearchField = () => import('@components/SearchField')
const CourierNameOverrideForm = () => import('@components/CourierNameOverrideForm')

const PRINT_TYPES = {
  UNPRINTED: 'unprinted',
  PRINTED: 'printed'
}

export default {
  name: 'print-page',
  components: {
    Navbar,
    PrintTrip,
    SearchField,
    CourierNameOverrideForm
  },
  mixins: [
    apiWithFiltersMixin,
    fulfilmentChannelMixin
  ],
  data() {
    return {
      PRINT_TYPES,
      isInitialTasksLoaded: false,
      searchTerm: '',
      checkedTasks: [],
      printedTrips: [],
      unprintedTrips: [],
      isPrinting: false,
      courierNameOverrideFormModalActive: false
    }
  },
  computed: {
    ...mapGetters('api', [
      'getLoading'
    ]),
    ...mapGetters('task', [
      'getTasks',
      'groupedTaskIds'
    ]),
    tasksLoading() {
      return this.getLoading('task/getTasks')
    },
    loadingOverlayVisible() {
      return (!this.isInitialTasksLoaded && this.tasksLoading) || this.isPrinting
    },
    columns() {
      return [
        {
          type: PRINT_TYPES.UNPRINTED,
          trips: this.unprintedTrips
        },
        {
          type: PRINT_TYPES.PRINTED,
          trips: this.printedTrips
        }
      ]
    },
    baseUnprintedTrips() {
      return this.getTrips(PRINT_TYPES.UNPRINTED)
    },
    basePrintedTrips() {
      return this.getTrips(PRINT_TYPES.PRINTED)
    },
    unprintedTripTasks() {
      return this.unprintedTrips.reduce((accumulated, trip) => {
        return accumulated.concat(trip.tasks)
      }, [])
    },
    printedTripTasks() {
      return this.printedTrips.reduce((accumulated, trip) => {
        return accumulated.concat(trip.tasks)
      }, [])
    },
    unprintedTaskIds() {
      return this.unprintedTripTasks.filter(task => !task.isCancelled).map(task => task.id)
    },
    printedTaskIds() {
      return this.printedTripTasks.filter(task => !task.isCancelled).map(task => task.id)
    }
  },
  watch: {
    groupedTaskIds() {
      if (!this.searchTerm) {
        this.resetTrips()
      }
    }
  },
  created() {
    this.resetTaskStore()

    this.watchLoadingOnce('tasksLoading', 'isInitialTasksLoaded')
  },
  methods: {
    ...mapActions('task', [
      'resetTaskStore',
      'getTasksRequest',
      'courierNameOverrideRequest'
    ]),
    // override method in mixin
    onReceived(data) {
      if (data.object === 'Task') {
        // TODO: check if will disrupt any user actions
        this.debounceRequest(this.getTasksApi)
      }
    },
    // override method in mixin
    apiRequest() {
      this.subscribeFulfilmentChannel()

      this.getTasksApi()
    },
    getTasksApi() {
      this.getTasksRequest({
        servingDate: formatDate(this.selectedDate),
        timeRangeStart: this.selectedTimeRange[0],
        timeRangeEnd: this.selectedTimeRange[1],
        hubIds: this.selectedHubIds,
        groupBy: 'printing'
      })
    },
    getTrips(type) {
      if (this.groupedTaskIds[type]) {
        return Object.values(this.groupedTaskIds[type]).map(printTrip => {
          return {
            ...printTrip,
            tasks: this.getTasks(...printTrip.taskIds).sort((a, b) => a.priority - b.priority)
          }
        })
      }
      return []
    },
    onSearchInput(searchTerm) {
      this.searchTerm = searchTerm
      this.search()
    },
    search() {
      if (this.searchTerm) {
        this.unprintedTrips = this.searchTrips(PRINT_TYPES.UNPRINTED)
        this.printedTrips = this.searchTrips(PRINT_TYPES.PRINTED)
      } else {
        this.resetTrips()
      }
    },
    resetTrips() {
      this.unprintedTrips = this.baseUnprintedTrips
      this.printedTrips = this.basePrintedTrips
    },
    searchTrips(type) {
      const filteredTrips = []
      const trips = type === PRINT_TYPES.UNPRINTED ? this.baseUnprintedTrips : this.basePrintedTrips
      const search = RegExp(this.searchTerm, 'i')
      trips.forEach(trip => {
        const filteredTasks = trip.tasks.filter(task => {
          return [
            task.orderIdentifier,
            task.customerName,
            task.customerContactNumber,
            task.deliveryAddress,
            task.rangeLabel
          ].some(value => search.test(value))
        })
        if (filteredTasks.length > 0) {
          filteredTrips.push({
            ...trip,
            tasks: filteredTasks
          })
        }
      })
      return filteredTrips
    },
    print() {
      this.closeErrorNotification()

      this.isPrinting = true
      printTasks({
        taskIds: this.checkedTasks,
        onSuccess: () => {
          this.isPrinting = false
          this.checkedTasks = []
          this.apiRequest()
        },
        onFailed: (error) => {
          this.errorNotification = this.openErrorNotification({
            message: `${error.response.statusText}: ${error.message}`
          })
        }
      })
    },
    selectAll(type) {
      const taskIds = type === PRINT_TYPES.UNPRINTED ? this.unprintedTaskIds : this.printedTaskIds
      const uncheckedTaskIds = taskIds.filter(taskId => !this.checkedTasks.includes(taskId))
      if (uncheckedTaskIds.length > 0) {
        this.checkedTasks = this.checkedTasks.concat(uncheckedTaskIds)
      } else {
        this.checkedTasks = this.checkedTasks.filter(taskId => !taskIds.includes(taskId))
      }
      this.openAllTrips(type)
    },
    openAllTrips(type) {
      const trips = type === PRINT_TYPES.UNPRINTED ? this.unprintedTrips : this.printedTrips
      trips.forEach(trip => {
        // refs in a v-for loop will return an array of objects instead of an object
        this.$refs[`trip_${trip.tripId}_${type}`][0].open()
      })
    },
    showCourierNameOverrideForm() {
      this.courierNameOverrideFormModalActive = true
    },
    confirmCourierNameOverrideForm(payload) {
      this.$eventBus.$off('task/courierNameOverrideSuccess', this.onCourierNameOverrideSuccess)
      this.$eventBus.$off('task/courierNameOverrideFailed', this.onCourierNameOverrideFailed)
      this.$eventBus.$on('task/courierNameOverrideSuccess', this.onCourierNameOverrideSuccess)
      this.$eventBus.$on('task/courierNameOverrideFailed', this.onCourierNameOverrideFailed)
      this.courierNameOverrideRequest(payload)
    },
    onCourierNameOverrideSuccess() {
      this.$eventBus.$off('task/courierNameOverrideSuccess', this.onCourierNameOverrideSuccess)
      this.$eventBus.$off('task/courierNameOverrideFailed', this.onCourierNameOverrideFailed)
      this.courierNameOverrideFormModalActive = false
      this.openToast({
        message: this.$t('print.courierNameOverrideForm.successMessage'),
        type: 'is-success'
      })
    },
    onCourierNameOverrideFailed() {
      this.$eventBus.$off('task/courierNameOverrideSuccess', this.onCourierNameOverrideSuccess)
      this.$eventBus.$off('task/courierNameOverrideFailed', this.onCourierNameOverrideFailed)
      this.courierNameOverrideFormModalActive = false
      this.openToast({
        message: this.$t('print.courierNameOverrideForm.failureMessage'),
        type: 'is-danger'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.print-page {
  .container {
    margin-top: $space-l;

    .vue-position-sticky {
      .print-action-bar {
        padding-bottom: 0;
        background-color: white;
      }
    }

    .print-action-bar {
      padding-bottom: $space-s;
      border-bottom: 1px solid $grey-dark;
    }

    .print-selection-section {
      padding-top: $space-s;

      .title {
        color: $black;
        @extend %display_medium;
      }

      .is-pulled-right {
        @extend %heading;
      }
    }

    @include touch {
      padding-right: $space-m;
      padding-left: $space-m;
    }

    .print-button {
      @extend %body;
    }
  }
}
</style>
